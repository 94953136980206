// /* eslint-disable */
import React, { Component } from 'react';
import {Button, Card, Col, Divider, Input, message, Modal, Popconfirm, Row, Select, Table, Tooltip} from 'antd';
import {EditOutlined, DeleteOutlined, PlusOutlined, EyeOutlined} from '@ant-design/icons';
import axios from 'axios';
import UpdateEditStock from './UpdateEditStock';
import Cookies from "js-cookie";
import {Redirect} from "react-router-dom";

const { Option } = Select;

const { Search } = Input;

class EditStock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableData: [],
            filteredTableData: [],
            isUpdateCustomerModalVisible: false,
            isViewItemModalVisible: false,
            selectedCustomer: null,
        };

        // Bind methods
        this.handleUpdateShow = this.handleUpdateShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getAllItems = this.getAllItems.bind(this);
        this.toggleUpdateCustomerModal = this.toggleUpdateCustomerModal.bind(this);
    }

    async componentDidMount() {
        this.getAllItems();
    }


    handleSearch = (value) => {
        const { tableData } = this.state;

        // Filter the table data based on Item Code and Item Name
        const filteredData = tableData.filter((record) => {
            return record.CODE.toLowerCase().includes(value.toLowerCase()) || record.NAME.toLowerCase().includes(value.toLowerCase());
        });

        this.setState({
            filteredTableData: filteredData,
        });

        if(filteredData.length === 0){
            message.info('No Item Found');
        }
    };

    handleUpdateShow(row) {
        this.setState({
            selectedCustomer: row,
            isUpdateCustomerModalVisible: true,
        });
    }

    handleDelete = async (Id) => {
        try {
            // Make an API call to deactivate the Item
            const response = await axios.post('https://iontheway.store/api/deactivateItem', {
                ITEM_ID: Id,
            });

            if (response.data.success) {
                message.success('Item deleted successfully');
                // Refresh the table
                await this.getAllItems();
            } else {
                message.error('Failed to delete Item');
            }
        } catch (error) {
            console.error('Error deleting Item:', error);
            message.error('Internal server error');
        }
    };


    async getAllItems() {
        this.setState({ loading: true });

        try {
            const response = await axios.post('https://iontheway.store/api/getAllItems');

            if (response.data.success) {
                const customers = response.data.result;
                console.log('customers', customers);

                this.setState({
                    tableData: customers,
                });
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }


    toggleUpdateCustomerModal() {
        this.setState({
            isUpdateCustomerModalVisible: !this.state.isUpdateCustomerModalVisible,
        });
    }

    showCustomer(customerId){
        this.setState({
            selectedCustomer1: customerId,
            isViewModalCustomerVisible1: true,
        });
    }

    render() {
        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        let rememberedUser = Cookies.get('rememberedUser');
        let ROLE = null;

        if (rememberedUser) {
            rememberedUser = JSON.parse(rememberedUser);
            ROLE = rememberedUser.ROLE;
        }

        if(ROLE === 'USER_MILL'){
            return <Redirect to="/dashboard" />
        }

        return (
            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Items"
                                extra={
                                    <>
                                        <Search
                                            placeholder="Search by Code or Name"
                                            onSearch={this.handleSearch}
                                            style={{ width: 300, margin: '10px' }}
                                            allowClear
                                        />
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        style={{ margin: '15px' }}
                                        rowKey="CODE"
                                        columns={[
                                            {
                                                title: 'Item Code',
                                                dataIndex: 'CODE',
                                            },

                                            {
                                                title: 'Item Name',
                                                dataIndex: 'NAME',
                                            },
                                            {
                                                title: 'Buying Price',
                                                dataIndex: 'BUYING_PRICE',
                                                align: 'right',
                                                render: (text, record) => {
                                                    return 'Rs ' + record.BUYING_PRICE.toFixed(2)
                                                }
                                            },
                                            {
                                                title: 'Sell Price',
                                                dataIndex: 'SELLING_PRICE',
                                                align: 'right',
                                                render: (text, record) => {
                                                    return 'Rs ' + record.SELLING_PRICE.toFixed(2)
                                                }
                                            },
                                            {
                                                title: 'Current Stock',
                                                dataIndex: 'STOCK',
                                                align: 'center',
                                                render: (text, record) => {
                                                    return record.STOCK.toFixed(2);
                                                }
                                            },
                                            {
                                                title: 'Unit',
                                                dataIndex: 'UNIT',
                                                align: 'left',
                                            },
                                            {
                                                title: 'Action',
                                                width: '120px',
                                                align: 'center',
                                                render: (row) => (
                                                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Tooltip title="Edit">
                                                                <Button
                                                                    type="primary"
                                                                    icon={<EditOutlined />}
                                                                    size="large"
                                                                    style={buttonStyle}
                                                                    onClick={() => this.handleUpdateShow(row)}
                                                                />
                                                            </Tooltip>
                                                            <Divider type="vertical" style={{ height: '50px', display: 'flex', alignItems: 'center' }} />
                            <Tooltip title="Delete">
  <Popconfirm
      title="Are you sure you want to delete this Item?"
      onConfirm={() => this.handleDelete(row.ITEM_ID)}
      okText="Yes"
      cancelText="No"
  >
    <Button
        type="primary"
        danger
        icon={<DeleteOutlined />}
        size="large"
        style={buttonStyle}
    />
  </Popconfirm>
</Tooltip>
                          </span>
                                                ),
                                            },
                                        ]}
                                        dataSource={
                                            this.state.filteredTableData.length > 0
                                                ? this.state.filteredTableData
                                                : this.state.tableData
                                        }
                                        pagination={true}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>

                {/* Update Item Modal */}
                <Modal
                    title="Update Item"
                    visible={this.state.isUpdateCustomerModalVisible}
                    onCancel={this.toggleUpdateCustomerModal}
                    footer={null}
                    width={1100}
                >
                    {this.state.selectedCustomer && (
                        <UpdateEditStock
                            key={this.state.selectedCustomer.ITEM_ID}
                            initialValues={this.state.selectedCustomer}
                            type={this.state.formType}
                            onUpdate={this.getAllItems}
                            onCancel={this.toggleUpdateCustomerModal}
                        />
                    )}
                </Modal>


            </>
        );
    }
}

export default EditStock;
