// /* eslint-disable */
import React, { useEffect, useState } from 'react';
import {Button, Card, Col, InputNumber, message, Popconfirm, Row, Table, Tooltip, Typography} from 'antd';
import axios from 'axios';
import LineChart from '../components/chart/LineChart';
import Paragraph from "antd/lib/typography/Paragraph";
import {CheckOutlined, DeleteOutlined, EditOutlined, RightCircleOutlined} from "@ant-design/icons";
import Cookies from "js-cookie";
import {NavLink} from "react-router-dom";
import AddTransactionForm from "./Store/AddTransactionForm/AddTransactionForm";
import moment from "moment";

const { Title } = Typography;

function Home() {
  const [loading, setLoading] = useState(false);
  const [store1Transactions, Setstore1Transactions] = useState([]);
  const [store1Stocks, Setstore1Stocks] = useState([]);
  const [expiringCheques, SetExpiringCheques] = useState([]);

  useEffect(() => {
    fetchStore1TodayTransactions();
    fetchStore1Stocks();
    fetchExpiringCheques();
  }, []);

  const fetchStore1TodayTransactions = async () => {
    setLoading(true);

    try {
      const response = await axios.post('https://iontheway.store/api/getTodayTransactions');

      if (response.data.success) {
        // console.log('ResponseDashboard:', response.data.result);
        Setstore1Transactions(response.data.result);
      } else {
        //console.log('Error:', response.data.message);
      }
    } catch (error) {
      //console.log('Error:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchStore1Stocks = async () => {
    setLoading(true);

    try {
      const response = await axios.post('https://iontheway.store/api/getAllItems');
      if (response.data.success) {
        //console.log('ResponseDashboard1:', response.data.result);
        Setstore1Stocks(response.data.result);
      } else {
        //console.log('Error:', response.data.message);
      }
    } catch (error) {
      //console.log('Error:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchExpiringCheques = async () => {
    setLoading(true);

    try {
      const response = await axios.post('https://iontheway.store/api/getExpiringCheques');

      if (response.data.success) {
        // console.log('ResponseDashboard312313123:', response.data.result);
        SetExpiringCheques(response.data.result);
      } else {
        //console.log('Error:', response.data.message);
      }
    } catch (error) {
      //console.log('Error:', error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleMarkAsReceived = async (transactionId) => {
    setLoading(true);

    console.log('Transaction ID:', transactionId);

    try {
      const response = await axios.post('https://iontheway.store/api/markAsReceived', { TRANSACTION_ID: transactionId });

      if (response.data.success) {
        message.success('Cheque marked as received successfully');
        fetchExpiringCheques();
      } else {
        message.error('Failed to mark cheque as received');
      }
    } catch (error) {
      //console.log('Error:', error.message);
    } finally {
      setLoading(false);
    }
  }

  let rememberedUser = Cookies.get('rememberedUser');

  let NAME1 = "NO User";
    let ROLE1 = "USER";

  if (rememberedUser) {
    rememberedUser = JSON.parse(rememberedUser);
    const { USER_ID, NAME,ROLE } = rememberedUser;
    NAME1 = NAME;
    ROLE1 = ROLE;
    //console.log(`User ID: ${USER_ID}, Name: ${NAME}`);
  }
  else{
    Cookies.remove('rememberedUser');
    window.location.href = '/';
  }

  // const renderCountCards = () => {
  //   if (!countData) return null;
  //
  //   return (
  //       <>
  //         <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
  //           <Card bordered={false} className="circlebox">
  //             <div className="number">
  //               <Row gutter={[24, 0]}>
  //
  //                 {/* Rough Section */}
  //                 {countData.roughCounts && (
  //                     <Col xs={24} sm={24} md={12} lg={12}>
  //                       <Card
  //                           bordered
  //                           className="circlebox-subcard"
  //                           style={{
  //                             width: '100%',
  //                             height: 'auto', // Set 'auto' for mobile view
  //                             marginBottom: '16px',
  //                             background: 'linear-gradient(to right, #003BACFF, #5D91FFFF)',
  //                             padding: '16px',
  //                             flexDirection: 'column',
  //                             justifyContent: 'left',
  //                             alignItems: 'center',
  //                           }}
  //                       >
  //                         {(() => {
  //                           let total = 0;
  //                           for (let i = 0; i < countData.roughCounts.length; i++) {
  //                             total += countData.roughCounts[i].COUNT;
  //                           }
  //                           return <Title level={3} style={{ color: '#fff' }}> Total Roughs 🪨 - {total} </Title>;
  //                         })()}
  //                         <NavLink to="/rough">
  //                           <Paragraph style={{ color: '#dfeafb' }}>
  //                             More Details <RightCircleOutlined style={{ color: '#dfeafb' }} />
  //                           </Paragraph>
  //                         </NavLink>
  //                         <div className="chart-visitor-count">
  //                           {countData.roughCounts.map((v, index) => (
  //                               <div key={index}>
  //                                 <Title level={4} style={{ color: '#fff' }}>{v.ROUGH_TYPE} - {v.COUNT}</Title>
  //                               </div>
  //                           ))}
  //                         </div>
  //                       </Card>
  //                     </Col>
  //                 )}
  //
  //                 {/* Lots Section */}
  //                 {countData.lotsCounts && (
  //                     <Col xs={24} sm={24} md={12} lg={12}>
  //                       <Card
  //                           bordered
  //                           className="circlebox-subcard"
  //                           style={{
  //                             width: '100%',
  //                             height: 'auto', // Set 'auto' for mobile view
  //                             marginBottom: '16px',
  //                             background: 'linear-gradient(to right, #2A7200FF, #5E9D41FF)',
  //                             padding: '16px',
  //                             flexDirection: 'column',
  //                             justifyContent: 'left',
  //                             alignItems: 'center',
  //                           }}
  //                       >
  //                         {(() => {
  //                           let total = 0;
  //                           for (let i = 0; i < countData.lotsCounts.length; i++) {
  //                             total += countData.lotsCounts[i].COUNT;
  //                           }
  //                           return <Title level={3} style={{ color: '#fff' }}> Total Lots 📦 - {total} </Title>;
  //                         })()}
  //                         <NavLink to="/lots">
  //                           <Paragraph style={{ color: '#dfeafb' }}>
  //                             More Details <RightCircleOutlined style={{ color: '#dfeafb' }} />
  //                           </Paragraph>
  //                         </NavLink>
  //                         <div className="chart-visitor-count">
  //                           {countData.lotsCounts.map((v, index) => (
  //                               <div key={index}>
  //                                 <Title level={4} style={{ color: '#fff' }}>{v.LOT_TYPE} - {v.COUNT}</Title>
  //                               </div>
  //                           ))}
  //                         </div>
  //                       </Card>
  //                     </Col>
  //                 )}
  //
  //                 {/* Sorted Lots Section */}
  //                 {countData.sortedLotsCounts && (
  //                     <Col xs={24} sm={24} md={12} lg={12}>
  //                       <Card
  //                           bordered
  //                           className="circlebox-subcard"
  //                           style={{
  //                             width: '100%',
  //                             height: 'auto', // Set 'auto' for mobile view
  //                             marginBottom: '16px',
  //                             background: 'linear-gradient(to right, #2F294FFF, #534E6BFF)',
  //                             padding: '16px',
  //                             flexDirection: 'column',
  //                             justifyContent: 'left',
  //                             alignItems: 'center',
  //                           }}
  //                       >
  //                         {(() => {
  //                           let total = 0;
  //                           for (let i = 0; i < countData.sortedLotsCounts.length; i++) {
  //                             total += countData.sortedLotsCounts[i].COUNT;
  //                           }
  //                           return <Title level={3} style={{ color: '#fff' }}> Total Sorted Lots 📦 - {total} </Title>;
  //                         })()}
  //                         <NavLink to="/sorted-lots">
  //                           <Paragraph style={{ color: '#dfeafb' }}>
  //                             More Details <RightCircleOutlined style={{ color: '#dfeafb' }} />
  //                           </Paragraph>
  //                         </NavLink>
  //                         <div className="chart-visitor-count">
  //                           {countData.sortedLotsCounts.map((v, index) => (
  //                               <div key={index}>
  //                                 <Title level={4} style={{ color: '#fff' }}>{v.SORTED_LOT_TYPE} - {v.COUNT}</Title>
  //                               </div>
  //                           ))}
  //                         </div>
  //                       </Card>
  //                     </Col>
  //                 )}
  //
  //                 {/* Cut and Polished Section */}
  //                 {countData.cutAndPolishedCounts && (
  //                     <Col xs={24} sm={24} md={12} lg={12}>
  //                       <Card
  //                           bordered
  //                           className="circlebox-subcard"
  //                           style={{
  //                             width: '100%',
  //                             height: 'auto', // Set 'auto' for mobile view
  //                             marginBottom: '16px',
  //                             background: 'linear-gradient(to right, #A81313FF, #A81313CB)',
  //                             padding: '16px',
  //                             flexDirection: 'column',
  //                             justifyContent: 'left',
  //                             alignItems: 'center',
  //                           }}
  //                       >
  //                         {(() => {
  //                           let total = 0;
  //                           for (let i = 0; i < countData.cutAndPolishedCounts.length; i++) {
  //                             total += countData.cutAndPolishedCounts[i].COUNT;
  //                           }
  //                           return <Title level={3} style={{ color: '#fff' }}> Total Cut and Polished 💎 - {total} </Title>;
  //                         })()}
  //                         <NavLink to="/c-p">
  //                           <Paragraph style={{ color: '#dfeafb' }}>
  //                             More Details <RightCircleOutlined style={{ color: '#dfeafb' }} />
  //                           </Paragraph>
  //                         </NavLink>
  //                         <div className="chart-visitor-count">
  //                           {countData.cutAndPolishedCounts.map((v, index) => (
  //                               <div key={index}>
  //                                 <Title level={4} style={{ color: '#fff' }}>{v.CP_TYPE} - {v.COUNT}</Title>
  //                               </div>
  //                           ))}
  //                         </div>
  //                       </Card>
  //                     </Col>
  //                 )}
  //               </Row>
  //             </div>
  //           </Card>
  //         </Col>
  //       </>
  //   );
  // };


  return (

      <>
            <div className="layout-header">
              <Title level={3} style={{ marginBottom: 20 }}>Welcome to On The Way, {NAME1}! 👋</Title>
            </div>


        <div className="layout-content">
          {ROLE1 === "ADMIN" ? (
          <Row gutter={[16, 16]} justify="left" align="top">
            {/*<Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">*/}
            {/*  <Card bordered={false} className="criclebox h-full">*/}
            {/*    <LineChart />*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <Card bordered={false} className="criclebox h-full"
                >
                <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-12">
                <Card bordered={false} className="criclebox h-full">
                <div className="table-responsive">
                  <Table
                      className="ant-border-space"
                      size="small"
                      style={{ margin: '15px' }}
                      rowKey="ITEM_ID"
                      columns={[
                        {
                          title: 'Item Name',
                          dataIndex: 'NAME',
                        },
                        {
                          title: 'Current Stock',
                          dataIndex: 'STOCK',
                          align: 'center',
                          render: (text, record) => {
                            return record.STOCK + ' ' + record.UNIT;
                          }
                        },
                        {
                          title: 'Buying Price',
                          dataIndex: 'BUYING_PRICE',
                          align: 'right',
                          render: (text, record) => {
                            return 'Rs ' + record.BUYING_PRICE.toFixed(2)
                          }
                        },
                        {
                          title: 'Sell Price',
                          dataIndex: 'SELLING_PRICE',
                          align: 'right',
                          render: (text, record) => {
                            return 'Rs ' + record.SELLING_PRICE.toFixed(2)
                          }
                        },

                      ]}
                      dataSource={store1Stocks}
                      pagination={{
                        pageSize: 5
                      }}
                      loading={loading}
                  />

                </div>
                </Card>

                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-12">
                  <Card bordered={false} className="criclebox h-full">
                    <span><Title level={3}>Today's Transactions Summary</Title></span>
                    <span><Title level={5} style={{ color: 'green' }}>Income: Rs {parseFloat(store1Transactions[1]).toFixed(2)}</Title></span>
                    <span><Title level={5} style={{ color: 'red' }}>OutGoings: Rs {parseFloat(store1Transactions[0]).toFixed(2)}</Title></span>
                    <span><Title level={5}>Balance: Rs {parseFloat(store1Transactions[1] - store1Transactions[0]).toFixed(2)}</Title></span>
                  </Card>
                </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={24} className="mb-12">
                    <Card bordered={false} className="criclebox h-full">
                      <div className="table-responsive">
                        <Table
                            className="ant-border-space"
                            size="small"
                            style={{ margin: '15px' }}
                            title={() => <Title level={3}>Checks and Credits Expiry within 15 days</Title>}
                            rowKey="TRANSACTION_ID"
                            columns={[
                              {
                                title: 'Transaction Code',
                                dataIndex: 'CHEQUE_NO',
                                // Red color for expired checks with a - sign
                                render: (text, record) => {
                                  return record.IS_EXPIRED === 1 ? (
                                      record.METHOD === 'Cheque' ? (
                                          <span style={{ color: 'red' }}>{record.CHEQUE_NO}</span>
                                      ) : (
                                          <span style={{ color: 'red' }}>N / A</span>
                                      )
                                  ) : (
                                      record.METHOD === 'Cheque' ? (
                                          <span>{record.CHEQUE_NO}</span>
                                      ) : (
                                          <span>N / A</span>
                                      )
                                  );
                                },
                              },


                              {
                                title: 'Amount',
                                dataIndex: 'SUB_TOTAL',
                                align: 'right',
                                render: (text, record) => {
                                    return 'Rs ' + parseFloat(record.SUB_TOTAL).toFixed(2)
                                    }
                              },
                              {
                                title: 'Transaction Code',
                                dataIndex: 'CODE',
                                align: 'center',
                              },
                              {
                                title: 'Expiry Date',
                                dataIndex: 'CHEQUE_EXPIRY',
                                render: (text, record) => {
                                  return record.CHEQUE_EXPIRY ? moment(record.CHEQUE_EXPIRY).format('YYYY-MM-DD') : '';
                                }
                              },
                              {
                                title: 'Bank',
                                dataIndex: 'CHEQUE_BANK',
                                align: 'center',
                                render: (text, record) => {
                                    return record.CHEQUE_BANK ? record.CHEQUE_BANK : '';
                              }
                                },
                              {
                                title: 'Action',
                                width: '120px',
                                align: 'center',
                                render: (row) => (
                                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Tooltip title="Mark as Received">
  <Popconfirm
      title="Are you sure you want to mark this cheque as received?"
      onConfirm={() => handleMarkAsReceived(row.TRANSACTION_ID)}
      okText="Yes"
      cancelText="No"
  >
    <Button
        type="primary"
        icon={<CheckOutlined />}
        size="small"
        style={{
          padding: '0px 4px', //set background color to green
          backgroundColor: '#52c41a',
        }}
    />
  </Popconfirm>
</Tooltip>
                                                        </span>
                                ),
                                },
                            ]}
                            dataSource={expiringCheques}
                            pagination={{
                              pageSize: 5
                            }}
                            loading={loading}
                        />

                      </div>
                    </Card>

                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <Card style={{ padding: '20px' }}
                    className="criclebox tablespace mb-24">
                <LineChart/>
              </Card>
            </Col>

          </Row>
          ) :
              <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-12">
                  <Card bordered={false} className="criclebox h-full">
                    <div className="table-responsive">
                      <Table
                          className="ant-border-space"
                          size="small"
                          style={{ margin: '15px' }}
                          rowKey="ITEM_ID"
                          columns={[
                            {
                              title: 'Item Name',
                              dataIndex: 'NAME',
                            },
                            {
                              title: 'Current Stock',
                              dataIndex: 'STOCK',
                              align: 'center',
                              render: (text, record) => {
                                return record.STOCK;
                              }
                            },
                            {
                              title: 'Current Price Per Unit',
                              dataIndex: 'PRICE',
                              align: 'right',
                              render: (text, record) => {
                                return 'Rs ' + record.PRICE.toFixed(2)
                              }
                            },

                          ]}
                          dataSource={store1Stocks}
                          pagination={{
                            pageSize: 5
                          }}
                          loading={loading}
                      />

                    </div>
                    </Card>
                </Col>
              </Row>
            }
        </div>

      </>
  );
}

export default Home;
