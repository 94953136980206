// /* eslint-disable */
import React, {Component} from "react";
import {
    Card,
    Row,
    Col,
    Form,
    InputNumber,
    Select,
    Button,
    message,
    DatePicker,
    Input, Divider, Switch, Table
} from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import moment from 'moment';
import {DeleteOutlined, EditOutlined, PlusOutlined, RightOutlined} from "@ant-design/icons";
import {Redirect} from "react-router-dom";

const { Option } = Select;

export default class EditTransactionsUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {

            customerOptions: [],
            tableData: this.props.initialValues.ITEMS,
            itemMaxQuantity: 999999999,
            currentItemName: '',
            showOverMaxQuantityWarning: false,
            currentItemCode: '',
            ReferenceOptions: [],
            type: this.props.initialValues.TYPE,
            Unit: 'Unit',
            method: this.props.initialValues.METHOD,
            discount: this.props.initialValues.DISCOUNT_AVAILABLE,
            itemSubTotal: 0,
        };
    }

    formRef = React.createRef();



    handleCalculateDueAmount = () => {
        const form = this.formRef.current;
        if (form) {
            const soldAmount = form.getFieldValue('SUB_TOTAL') || 0;
            const amountReceived = form.getFieldValue('AMOUNT_SETTLED') || 0;

            const dueAmount = soldAmount - amountReceived;

            form.setFieldsValue({ DUE_AMOUNT: dueAmount.toFixed(2) });
        }
    };

    handleCalculateTotal = () => {
        const form = this.formRef.current;
        if (form) {
            const soldAmount = form.getFieldValue('AMOUNT') || 0;
            const sharePercentage = form.getFieldValue('SHARE_PERCENTAGE') || 0;

            const total = soldAmount * sharePercentage / 100;

            form.setFieldsValue({ SHARE_VALUE: total });
        }
    };

    handleTransactionTypeChange = async (value) => {
        this.setState({ type: value , tableData: [] });
        const form = this.formRef.current;
        form.resetFields(['ITEM', 'PRICE', 'QUANTITY', 'TOTAL', 'SUB_TOTAL']);
    }



    async componentDidMount() {
        // Fetch customer options when the component mounts
        const customerOptions = await this.fetchCustomerOptions();
        this.setState({ customerOptions });
        const ReferenceOptions = await this.fetchReferenceOptions();
        this.setState({ ReferenceOptions });
    }

    async fetchCustomerOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getAllCustomers");
            //console.log("response", response);

            return response.data.result.map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }));
        } catch (error) {
            console.error("Error fetching customer options:", error);
            return [];
        }
    }

    async fetchReferenceOptions1() {
        try {
            const response = await axios.post("https://iontheway.store/api/getItemsForReference");
            console.log("response", response);
            this.state.ReferenceOptions = response.data.result.map((ref) => ({
                    value: ref.ITEM_ID,
                    label: ref.STOCK > 0 ? `${ref.NAME} - ( ${ref.STOCK} )` : `${ref.NAME} - (Out of Stock)`,
                }
            ));
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getItemsForReference");
            console.log("response", response);
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                label: ref.STOCK > 0 ? `${ref.NAME} - ( ${ref.STOCK} )` : `${ref.NAME} - (Out of Stock)`,
            }));
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    }

    handleCalculateTotal = () => {
        const form = this.formRef.current;
        if (form) {
            const price = form.getFieldValue('PRICE') || 0;
            const quantity = form.getFieldValue('QUANTITY') || 0;

            const total = price * quantity;
            //limit to 2 decimal places
            const Fixedtotal = total.toFixed(2);

            form.setFieldsValue({ TOTAL: Fixedtotal });
            if(quantity > this.state.itemMaxQuantity){
                this.setState({ showOverMaxQuantityWarning: true });
            } else {
                this.setState({ showOverMaxQuantityWarning: false });
            }
        }
    }



    handleReferenceChange = async (value) => {
        //console.log(`selected ${value}`);
        const form = this.formRef.current;
        try {
            const response = await axios.post('https://iontheway.store/api/getItemsDetailsForTransaction', {
                ITEM_ID: value,
            });

            if (response.data.success) {
                //console.log("response", response);
                if(this.state.type === 'Selling'){
                    form.setFieldsValue({ PRICE: response.data.result.SELLING_PRICE });
                }
                else{
                    form.setFieldsValue({ PRICE: response.data.result.BUYING_PRICE });
                }


                this.setState({
                        itemMaxQuantity: response.data.result.STOCK,
                        currentItemName: response.data.result.NAME,
                        currentItemCode: response.data.result.CODE,
                        showOverMaxQuantityWarning: false,
                        Unit: response.data.result.UNIT,
                    }
                );
                form.resetFields(['QUANTITY', 'TOTAL']);
            } else {
                message.error('Failed to fetch Item Details');
            }
        } catch (error) {
            console.error('Error fetching Item Details:', error);
            message.error('Internal server error');
        }
    };

    handleAddItemToTable = async () => {
        const form = this.formRef.current;
        if (form.getFieldValue('ITEM') !== undefined && form.getFieldValue('PRICE') !== undefined && form.getFieldValue('QUANTITY') !== undefined && form.getFieldValue('TOTAL') !== undefined) {

            const currentItem = {
                ITEM_ID: form.getFieldValue('ITEM'),
                ITEM_CODE: this.state.currentItemCode,
                ITEM_NAME: this.state.currentItemName,
                PRICE: parseFloat(form.getFieldValue('PRICE')),
                QUANTITY: parseFloat(form.getFieldValue('QUANTITY')),
                TOTAL: parseFloat(form.getFieldValue('TOTAL')),
            };

            form.setFieldsValue({ DUE_AMOUNT: undefined });

            const existingItemIndex = this.state.tableData.findIndex(
                (item) => item.ITEM_ID === currentItem.ITEM_ID && item.PRICE === currentItem.PRICE
            );

            if (existingItemIndex !== -1) {
                // If the item with the same ITEM_ID and PRICE exists, update its QUANTITY and TOTAL
                const updatedTableData = [...this.state.tableData];
                updatedTableData[existingItemIndex].QUANTITY += currentItem.QUANTITY;
                updatedTableData[existingItemIndex].TOTAL += currentItem.TOTAL;
                await this.setState({tableData: updatedTableData});
            } else {
                // If the item with the same ITEM_ID and PRICE doesn't exist, add a new row
                await this.setState((prevState) => ({tableData: [...prevState.tableData, currentItem]}));
            }

            form.resetFields(['ITEM', 'PRICE', 'QUANTITY', 'TOTAL']);

            // Calculate Sub Total and update the form
            const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
            this.state.itemSubTotal = subTotal;
            form.setFieldsValue({SUB_TOTAL: subTotal.toFixed(2)});
            this.handleCalculateSubTotal();
        } else {
            message.error('Please fill all the fields');
        }
    };

    handleSubmit = async (values) => {
        try {
            // Retrieve USER_ID from rememberedUser
            const rememberedUser = Cookies.get('rememberedUser');
            const USER_ID = rememberedUser ? JSON.parse(rememberedUser).USER_ID : null;

            const transactionData = {
                ...values,
                ITEMS: this.state.tableData,
                TRANSACTION_ID: this.props.initialValues.TRANSACTION_ID,
            };
            console.log('transactionData', transactionData);

            const response = await axios.post('https://iontheway.store/api/updateTransaction', transactionData);
            console.log('response', response);

            if (response.data.success) {
                message.success('Transaction updated successfully');
                this.props.onUpdate();
                this.props.onCancel();
            } else {
                message.error('Failed to update Transaction');
            }
        } catch (error) {
            console.error('Error adding Transaction:', error);
            message.error('Internal server error');
        }
    }

    handleDeleteItem = async (record) => {
        await this.setState((prevState) => ({
            tableData: prevState.tableData.filter((item) => item !== record),
        }));

        // Calculate Sub Total and update the form
        const form = this.formRef.current;
        if (form) {
            const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
            this.state.itemSubTotal = subTotal;
            form.setFieldsValue({ SUB_TOTAL: subTotal.toFixed(2) });
            this.handleCalculateSubTotal();
            form.setFieldsValue({ DUE_AMOUNT: undefined });
        }
    }

    handleCalculateSubTotal = () => {
        //get SUB_TOTAL value and apply discount if available
        const form = this.formRef.current;
        if (form && this.state.discount && this.state.itemSubTotal) {
            const subTotal = this.state.itemSubTotal;
            const discount = form.getFieldValue('DISCOUNT') || 0;

            if(form.getFieldValue('DISCOUNT_TYPE') === 'Percentage'){
                const total = subTotal - (subTotal * discount / 100);
                form.setFieldsValue({ SUB_TOTAL: total.toFixed(2) });
            }
            else {
                const total = subTotal - discount;
                form.setFieldsValue({SUB_TOTAL: total.toFixed(2)});
            }
        }
        else {
            form.setFieldsValue({ SUB_TOTAL: this.state.itemSubTotal.toFixed(2) });
        }
    }

    handleEditItem = async (record) => {
        const form = this.formRef.current;
        if (form) {
            form.setFieldsValue({
                ITEM: record.ITEM_ID,
                PRICE: record.PRICE,
                QUANTITY: record.QUANTITY,
                TOTAL: record.TOTAL,
            });

            await this.setState((prevState) => ({
                tableData: prevState.tableData.filter((item) => item !== record),
            }));

            // Calculate Sub Total and update the form
            const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
            this.state.itemSubTotal = subTotal;
            form.setFieldsValue({ SUB_TOTAL: subTotal.toFixed(2) });
            this.handleCalculateSubTotal();

            this.setState({
                currentItemName: record.ITEM_NAME,
                currentItemCode: record.ITEM_CODE,
                itemMaxQuantity: record.QUANTITY,
                showOverMaxQuantityWarning: false,
            });
            form.setFieldsValue({ DUE_AMOUNT: undefined });
        }
    }



    render() {
        const inputStyle = {
            width: '100%',
            height: '30px',
        };

        let rememberedUser = Cookies.get('rememberedUser');
        let ROLE = null;

        if (rememberedUser) {
            rememberedUser = JSON.parse(rememberedUser);
            ROLE = rememberedUser.ROLE;
        }

        if(ROLE === 'USER_MILL'){
            return <Redirect to="/dashboard" />
        }

        const {   tableData,ReferenceOptions,type } = this.state;


        return (

            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                className="criclebox tablespace mb-24"
                            >
                                <Form
                                    layout="vertical"
                                    onFinish={this.handleSubmit}
                                    style={{ margin: '20px' }}
                                    ref={this.formRef}
                                >
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="TYPE"
                                                label="Transaction Type"
                                                initialValue={type}
                                                rules={[{ required: true, message: 'Please select Transaction Type' }]}
                                            >
                                                <Select placeholder="Select Transaction Type" allowClear showSearch onChange={this.handleTransactionTypeChange} disabled>
                                                    <Option value="Buying">Buying</Option>
                                                    <Option value="Selling">Selling</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                            <Col xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item
                                                    name="METHOD"
                                                    label="Payment Method"
                                                    rules={[{ required: true, message: 'Please select Payment Method' }]}
                                                    initialValue={this.props.initialValues.METHOD}
                                                >
                                                    <Select placeholder="Select Payment Method" allowClear showSearch onChange={(value) => this.setState({ method: value })}>
                                                        <Option value="Cash">Cash</Option>
                                                        <Option value="Card">Card</Option>
                                                        {this.state.type === 'Buying' ? (
                                                            <Option value="Credit">Credit</Option>
                                                        ) : null }
                                                        {this.state.type === 'Buying' ? (
                                                            <Option value="Cheque">Cheque</Option>
                                                        ) : null }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="DATE"
                                                label="Transaction Date"
                                                initialValue={moment(this.props.initialValues.DATE)}
                                                rules={[{ required: true, message: 'Please select Transaction Date' }]}
                                            >
                                                <DatePicker style={inputStyle} placeholder="Select Transaction Date" />
                                            </Form.Item>
                                        </Col>
                                        <Divider />
                                    </Row>
                                    {this.state.method === 'Cheque' ? (
                        <Row gutter={[16, 16]} justify="left" align="top">
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item
                                    name="CHEQUE_NO"
                                    label="Cheque Number"
                                    rules={[{ required: true, message: 'Please enter Cheque Number' }]}
                                    initialValue={this.props.initialValues.CHEQUE_NO}
                                >
                                    <Input style={inputStyle} placeholder="Enter Cheque Number" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item
                                    name="CHEQUE_EXPIRY"
                                    label="Cheque Expiry Date"
                                    initialValue={moment(this.props.initialValues.CHEQUE_EXPIRY)}
                                >
                                    <DatePicker style={inputStyle} placeholder="Select Cheque Expiry Date" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item
                                    name="CHEQUE_BANK"
                                    label="Cheque Bank"
                                    rules={[{ required: true, message: 'Please enter Cheque Bank' }]}
                                    initialValue={this.props.initialValues.CHEQUE_BANK}
                                >
                                    <Input style={inputStyle} placeholder="Enter Cheque Bank" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item
                                    name="IS_CHEQUE_COLLECTED"
                                    label="Cheque Collected"
                                    valuePropName="checked"
                                    initialValue={this.props.initialValues.IS_CHEQUE_COLLECTED}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Divider />
                        </Row>
                    ) : null }
                                    {this.state.method === 'Card' ? (
                                        <Row gutter={[16, 16]} justify="left" align="top">
                                            <Col xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item
                                                    name="CHEQUE_NO"
                                                    label="Card Transaction ID"
                                                    rules={[{ required: true, message: 'Please enter Card Transaction ID' }]}
                                                    initialValue={this.props.initialValues.CHEQUE_NO}
                                                >
                                                    <Input style={inputStyle} placeholder="Enter Card Transaction ID" />
                                                </Form.Item>
                                            </Col>
                                            <Divider />
                                        </Row>
                                    ) : null }
                                    {this.state.method === 'Credit' ? (
                                        <Row gutter={[16, 16]} justify="left" align="top">
                                            <Col xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item
                                                    name="CHEQUE_EXPIRY"
                                                    label="Credit Expiry Date"
                                                    initialValue={moment(this.props.initialValues.CHEQUE_EXPIRY)}
                                                >
                                                    <DatePicker style={inputStyle} placeholder="Select Credit Expiry Date" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item
                                                    name="IS_CHEQUE_COLLECTED"
                                                    label="Credit Collected"
                                                    valuePropName="checked"
                                                    initialValue={this.props.initialValues.IS_CHEQUE_COLLECTED}
                                                >
                                                    <Switch />
                                                </Form.Item>
                                            </Col>
                                            <Divider />
                                        </Row>
                                    ) : null }
                                    <Row gutter={[16, 16]} justify="left" align="top">

                                        <Col xs={24} sm={24} md={24} lg={9}>
                                            <Form.Item
                                                name="ITEM"
                                                label="Item"
                                            >
                                                <Select placeholder="Select Item" allowClear showSearch onChange={this.handleReferenceChange}
                                                        filterOption={(input, option) =>
                                                            (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                                            (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                                        }>
                                                    {ReferenceOptions.map((option) => (
                                                        <Option key={option.value} value={option.value} title={option.label}>
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={3}>
                                            <Form.Item
                                                name="PRICE"
                                                label={`Price Per ${this.state.Unit}`}
                                            >
                                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Price" onChange={this.handleCalculateTotal} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="QUANTITY"
                                                label="Quantity"

                                            >
                                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Quantity" onChange={this.handleCalculateTotal} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={5}>
                                            <Form.Item
                                                name="TOTAL"
                                                label="Total"
                                            >
                                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Total" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={1}>
                                            <Form.Item
                                                label=" "
                                                name="ADD_ITEM_TO_TABLE"
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={this.handleAddItemToTable}
                                                >
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {(this.state.showOverMaxQuantityWarning && type === 'Selling') ? <p style={{ color: 'red' }}>Quantity exceeds the available stock</p> : null}
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Table
                                            className="ant-border-space"
                                            columns={[
                                                {
                                                    title: 'Item Code',
                                                    dataIndex: 'ITEM_CODE',
                                                },
                                                {
                                                    title: 'Item Name',
                                                    dataIndex: 'ITEM_NAME',
                                                },
                                                {
                                                    title: 'Price Per Unit',
                                                    dataIndex: 'PRICE',
                                                    align: 'right',
                                                    render: (text, record) => {
                                                        return 'Rs ' + record.PRICE.toFixed(2)
                                                    }

                                                },
                                                {
                                                    title: 'Quantity',
                                                    dataIndex: 'QUANTITY',
                                                    align: 'right',
                                                    render: (text, record) => {
                                                        return record.QUANTITY;
                                                    }
                                                },
                                                {
                                                    title: 'Total (Rs)',
                                                    dataIndex: 'TOTAL',
                                                    align: 'right',
                                                    render: (text, record) => {
                                                        return 'Rs ' + record.TOTAL.toFixed(2);
                                                    }
                                                },
                                                {
                                                    title: 'Action',
                                                    dataIndex: 'action',
                                                    align: 'center',
                                                    render: (_, record) => (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Button type="default" onClick={() => this.handleEditItem(record)}>
                                                                <EditOutlined />
                                                            </Button>
                                                            <Divider type="vertical" />
                                                            <Button danger onClick={() => this.handleDeleteItem(record)}>
                                                                <DeleteOutlined />
                                                            </Button>
                                                        </div>
                                                    ),
                                                }
                                            ]}
                                            dataSource={tableData}
                                            rowKey={(record, index) => index}
                                            pagination={false}
                                            scroll={{ y: 240 }}
                                            bordered
                                            footer={() => {
                                                // Calculate and display the subtotal
                                                const subTotal = tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
                                                return (
                                                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                        Sub Total (Rs): {subTotal.toFixed(2)}
                                                    </div>
                                                );
                                            }}
                                        />

                                    </Row>
                                    <Divider />
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={3}>
                                            <Form.Item
                                                name="DISCOUNT_AVAILABLE"
                                                label="Discount Available"
                                                valuePropName="checked"
                                                initialValue={this.props.initialValues.DISCOUNT_AVAILABLE}
                                            >
                                                <Switch
                                                    onChange={(value) => {
                                                        this.setState({ discount: value }, () => {
                                                            this.handleCalculateSubTotal();
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {this.state.discount ? (
                                            <Col xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item
                                                    name="DISCOUNT_TYPE"
                                                    label="Discount Type"
                                                    initialValue={this.props.initialValues.DISCOUNT_TYPE}
                                                    rules={[{ required: this.state.discount, message: 'Please select Discount Type' }]}
                                                >
                                                    <Select placeholder="Select Discount Type"  showSearch onSelect={this.handleCalculateSubTotal}>
                                                        <Option value="Percentage">Percentage</Option>
                                                        <Option value="Fixed">Fixed</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        {this.state.discount ? (
                                            <Col xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item
                                                    name="DISCOUNT"
                                                    label="Discount"
                                                    rules={[{ required: this.state.discount, message: 'Please enter Discount' }]}
                                                    initialValue={this.props.initialValues.DISCOUNT}
                                                >
                                                    <InputNumber
                                                        style={inputStyle}
                                                        min={0}
                                                        step={0.01}
                                                        placeholder="Enter Discount"
                                                        onChange={this.handleCalculateSubTotal}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                    </Row>
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="SUB_TOTAL"
                                                label="Sub Total"
                                                rules={[{ required: true, message: 'Please enter Sub Total' }]}
                                                initialValue={this.props.initialValues.SUB_TOTAL}
                                            >
                                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Sub Total"/>
                                            </Form.Item>
                                        </Col>
                                        {/*{type === 'Selling' ? (*/}
                                        {/*    <Col xs={24} sm={24} md={24} lg={6}>*/}
                                        {/*        <Form.Item*/}
                                        {/*            name="AMOUNT_SETTLED"*/}
                                        {/*            label="Amount Settled"*/}
                                        {/*            initialValue={this.props.initialValues.AMOUNT_SETTLED}*/}
                                        {/*            rules={[{ required: true, message: 'Please enter Amount Settled' }]}*/}
                                        {/*        >*/}
                                        {/*            <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Amount Settled" />*/}
                                        {/*        </Form.Item>*/}
                                        {/*    </Col>*/}
                                        {/*) : null }*/}
                                        {/*{type === 'Selling' ? (*/}
                                        {/*    <Col xs={24} sm={24} md={24} lg={1}>*/}
                                        {/*        <Form.Item*/}
                                        {/*            label=" "*/}
                                        {/*            name="CALCULATE_DUE_AMOUNT"*/}
                                        {/*        >*/}
                                        {/*            <Button*/}
                                        {/*                type="default"*/}
                                        {/*                icon={<RightOutlined />}*/}
                                        {/*                onClick={this.handleCalculateDueAmount}*/}
                                        {/*            >*/}
                                        {/*            </Button>*/}
                                        {/*        </Form.Item>*/}
                                        {/*    </Col>*/}
                                        {/*) : null }*/}
                                        {/*{type === 'Selling' ? (*/}
                                        {/*    <Col xs={24} sm={24} md={24} lg={5}>*/}
                                        {/*        <Form.Item*/}
                                        {/*            name="DUE_AMOUNT"*/}
                                        {/*            label="Due Amount"*/}
                                        {/*            rules={[{ required: true, message: 'Please enter Due Amount' }]}*/}
                                        {/*            initialValue={this.props.initialValues.DUE_AMOUNT}*/}
                                        {/*        >*/}
                                        {/*            <InputNumber style={inputStyle} step={0.01} placeholder="Enter Due Amount" />*/}
                                        {/*        </Form.Item>*/}
                                        {/*    </Col>*/}
                                        {/*) : null }*/}
                                        {/*{type === 'Selling' ? (*/}
                                        {/*    <Col xs={24} sm={24} md={24} lg={6}>*/}
                                        {/*        <Form.Item*/}
                                        {/*            name="DUE_DATE"*/}
                                        {/*            label="Due Date"*/}
                                        {/*            initialValue={moment(this.props.initialValues.DUE_DATE)}*/}
                                        {/*        >*/}
                                        {/*            <DatePicker style={inputStyle} placeholder="Select Due Date" />*/}
                                        {/*        </Form.Item>*/}
                                        {/*    </Col>*/}
                                        {/*) : null }*/}
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item
                                                name="COMMENTS"
                                                label="Comments"
                                                initialValue={this.props.initialValues.COMMENTS}
                                            >
                                                <Input.TextArea rows={2} placeholder="Enter comments" />
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Update Transaction
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
